import React, {useEffect, useRef, useState} from 'react'

import {
    Box,
    Container,
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    CircularProgress,
    Snackbar,
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

//HOOKS
import {
    useTheme
} from '@material-ui/core/styles'
import {useHistory} from 'react-router-dom'
//RESOURCES
import bg from '../Resources/background.jpeg'
import {ReactComponent as Logo} from '../Resources/logo.svg'

export default function Login() {
    //HOOKS
    const theme = useTheme()
    const history = useHistory()

    // REFS
    const userInput = useRef()
    const mailInput = useRef()

    // STATES
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [openError, setOpenError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    // EFFECTS
    useEffect(() => {
        document.title = 'Transtrip | Recuperar contraseña'
    }, [])

    // FUNCTIONS
    const recovery = e => {
        e.preventDefault()
        setLoading(true)
        const username = userInput.current.value
        const mail = mailInput.current.value

        fetch('https://karbooking.com/home/external-request-password-reset', {
            method: 'POST',
            body: {
                'PasswordResetRequestForm[username]': username,
                'PasswordResetRequestForm[email]': mail,
            },
            cache: "no-store",
            xhrFields: {
                withCredentials: false
            },
            headers: {},
        })
        .then(res => res.json())
        .then(data => {
            if(data.sucess){
                setSuccess(true)
            }else{
                setErrorMessage("Usuario o mail inválido")
                setOpenError(true)
            }
        })
        .catch(err => {
            setErrorMessage(err.message)
            setOpenError(true)
        })
        .finally(() => setLoading(false))
    }

    const handleClose = () => setOpenError(false)

    return (
        <>
            <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>
            <Box 
                position="fixed"
                top={0}
                bottom={0}
                left={0}
                right={0}
                style={{
                    background: `url(${bg})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Container>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={10} sm={8} md={5}>
                            <Paper>
                                <Box 
                                    px={3}
                                    py={6}
                                    my={3}
                                    display="flex"
                                    flexDirection="column"
                                    alignItems="center"
                                    maxHeight="70vh"
                                    overflow="auto"
                                    style={{transition: "height 200ms ease-in-out"}}
                                >
                                    <Box component="a" href="/" width="45%">
                                        <Logo width="100%"/>
                                    </Box>
                                    <Box>
                                        <Box width={1} height={2} mt={1} mb={2} bgcolor={theme.palette.grey[200]} />
                                        <Typography align="center" variant="h6" color="primary" gutterBottom>Recuperar contraseña</Typography>
                                        {success === false && (
                                            <Typography align="center" variant="body2">Por favor ingrese su usuario y email.<br/> Se le enviará un link para reiniciar su contraseña.</Typography>
                                        )}
                                    </Box>
                                    {loading ? (
                                        <Box my={4}>
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            {success ? (
                                                <Box mt={4} >
                                                    <Typography align="center" variant="subtitle1" gutterBottom>
                                                        <b>Solicitud exitosa.</b><br/> 
                                                        Por favor revise su casilla de correo 
                                                    </Typography>
                                                    <Box mt={4} display="flex" justifyContent="center">
                                                        <Button variant="contained" color="primary" onClick={() => history.push("/login")} size="small" disableElevation>Volver al login</Button>
                                                    </Box>
                                                </Box>
                                            ) : (
                                                <Box
                                                    component="form"
                                                    onSubmit={recovery}
                                                    display="flex"
                                                    flexDirection="column"
                                                    width={1}
                                                    maxWidth={250}
                                                >
                                                    <Box mt={4}/>
                                                    <TextField inputRef={userInput} label="Usuario"/>
                                                    <Box mt={2}/>
                                                    <TextField inputRef={mailInput} type="email" label="Mail"/>
                                                    <Box mt={6}/>
                                                    <Button variant="contained" color="primary" type="submit" disableElevation>Enviar</Button>
                                                    <Box mt={4}/>
                                                    <Button onClick={() => history.push("/login")} size="small" disableElevation>Volver al login</Button>
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
}
