import React from 'react'
import {
    Typography,
    Box,
    Container,
    Button,
    Paper,
    Grid,
    TextField
} from '@material-ui/core'

import {
    Send as SendIcon
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

// RESOURCES
import background from '../Resources/background.jpeg'
import {ReactComponent as Logo} from '../Resources/logo.svg'

//CLIENTES
import laNacion from '../Resources/Clientes/la-nacion.svg'
import aa2000 from '../Resources/Clientes/aa2000.svg'
import mercadoLibre from '../Resources/Clientes/mercado-libre.svg'
import nestle from '../Resources/Clientes/nestle.svg'
import meta from '../Resources/Clientes/meta.png'

const useStyles = makeStyles((theme) => ({
    bg: {
        background: `linear-gradient(5deg, rgba(255,255,255,.9) 46%, rgba(255,255,255,0.45) 100%), url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom right',
    },
    whiteType: {
        color: "#fff"
    },
    paper: {
        width: '100%',
        paddingBottom: "56.25%",
        overflow: 'hidden'
    },
    reverseSection: {
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column-reverse',
          },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
          },
    }
  }))




export default () => {
    const classes = useStyles()

    return (
        <>
            {/* TOPBAR */}
            <Box height={56} position="fixed" top={0} right={0} left={0} style={{backgroundColor: "white", zIndex: 5}}>
                <Paper square elevation={3}>
                    <Container maxWidth="md">
                        <Box width={1} height={56} display="flex" alignItems="center">
                            <Box height={{xs: 30, sm: 35}}>
                                <Logo height="100%"/>

                            </Box>
                            <Box flexGrow={1} />
                            <Button variant="outlined" color="primary">Iniciar sesión</Button>
                        </Box>
                    </Container>
                </Paper>
            </Box>
            {/* TOPBAR */}
            {/* HERO SECTION */}
            <Box margin={0} width={1} height={{xs: "125vh", sm: "100vh"}} display="flex" flexDirection="column" alignItems="stretch"> 
                <Box height={56} />
                <Box className={classes.bg} flexGrow={1} display="flex" alignItems="center">
                    <Container maxWidth="md">
                        <Typography variant="h3" component="h1" color="primary" align="center">Traslados empresariales. Sin papeles.  </Typography>
                        <Typography variant="subtitle2" align="center">Solicitá traslados desde la web y controlalos sin necesidad de vouchers y llamados.</Typography>
                        <Box mt={8} display="flex" justifyContent="center">
                            <Grid 
                                container
                                justify="center"
                                alignItems="center"
                                spacing={2}
                            >
                                <Grid item xs={12} sm={6}>
                                    <TextField type="email" label="Tu email" variant="outlined" size="small" fullWidth/>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                            <Button 
                                            variant="contained" 
                                            color="primary" 
                                            endIcon={
                                                <SendIcon />
                                            } 
                                            disableElevation
                                        >
                                            Contactarnos</Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Box>
                <Box width={1} py={4}>
                    <Container maxWidth="lg">
                        <Grid container spacing={4} justify="space-between">
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <img alt="Logo La Nacion" src={laNacion} width="100%"/>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <img alt="Logo Aeropuertos Argentina 2000" src={aa2000} width="100%"/>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <img alt="Logo Mercado Libre" src={mercadoLibre} width="80%"/>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <img alt="Logo Nestle" src={nestle} width="85%"/>
                            </Grid>
                            <Grid item xs={12} sm={2} container justify="center" alignItems="center">
                                <img alt="Logo Drogeria Meta" src={meta} height="45px" />
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            {/* HERO SECTION */}
            {/* MAIN SECTION */}
            <Box display="flex" width={1} py={{xs: 6, sm: 10}} alignItems="center">
                <Box width={1}>
                    <Container maxWidth="md">
                        <Grid container spacing={4}>
                            {/* MODULO IZQUIERDA */}
                            <Grid item xs={12} sm={5} container direction="column" justify="center">
                                <Typography variant="h4" color="primary" align="right">Título destacado uno </Typography>
                                <Typography variant="body2" align="right">Una bajada bien piola de lo que estamos hablando</Typography>

                            </Grid>
                            <Grid item xs={12} sm={7}>
                                    <Paper className={classes.paper}>

                                    </Paper>
                            </Grid>
                            {/* MODULO IZQUIERDA */}
                        </Grid>
                    </Container>
                </Box>
            </Box>
            <Box display="flex" width={1} py={{xs: 6, sm: 10}} alignItems="center">
                <Box width={1}>
                    <Container maxWidth="md">
                        <Grid className={classes.reverseSection} container spacing={4}>
                            <Grid item xs={12} sm={7}>
                                    <Paper className={classes.paper}>

                                    </Paper>
                            </Grid>
                            <Grid item xs={12} sm={5} container direction="column" justify="center">
                                <Typography variant="h4" color="primary" align="left">Título destacado uno </Typography>
                                <Typography variant="body2" align="left">Una bajada bien piola de lo que estamos hablando</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            {/* MAIN SECTION */}
        </>
    )
}