import React, { useState, useEffect, Children } from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
    width: 100%;
    height: 50px;
    position: relative;
    & .img-container{
        opacity: 0;
        transition: opacity 2s ease-in-out; 
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        & .image{
            width: 100%;
            height: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
        }
    }
    & .img-container:nth-child(${props => props.active}){
        opacity: 1;
    }
`


export default ({delay, children, length}) => {
    const [active, setActive] = useState(1)

    useEffect(() => {
        setTimeout(() => {
            if(active === length ){
                setActive(1)
            } else{
                setActive(active => active +1)
            }
        }, delay * 1000 || 5000)
    }, [active, length])

    return (
        <MainContainer active={active}>
            {Children.map(children, child => (
                <div className="img-container">
                    {child}
                </div>
            ))}
        </MainContainer>
    )
}

