import React from 'react'

//MATERIAL
import {
    Box,
    Paper,
    Container,
    Button,
    Divider
} from '@material-ui/core'

//HOOKS
import {useLocation} from 'react-router-dom'

//RESOURCES
import {ReactComponent as Logo} from '../Resources/logo.svg'


export default ({scrollTo}) =>  {
    const {pathname} = useLocation()

    return (
        <Box height={56} position="fixed" top={0} right={0} left={0} style={{backgroundColor: "white", zIndex: 5}}>
            <Paper square elevation={3}>
                <Container maxWidth="md">
                    <Box width={1} height={56} display="flex" alignItems="center">
                        <Box component="a" href="/" height={{xs: 30, sm: 35}}>
                            <Logo height="100%"/>
                        </Box>
                        <Box flexGrow={1} />
                        <Box height="80%" display="flex" alignItems="center">
                            {pathname === '/' && (
                                <>
                                    <Box display={{xs: "none", md: "flex"}} alignItems="center">
                                        <Button onClick={() => scrollTo("contactSection")}>
                                            Contacto
                                        </Button>
                                        <Button onClick={() => scrollTo("opiniones")}>
                                            Opiniones
                                        </Button>
                                        <Box ml={2} />
                                        <Button onClick={() => scrollTo("appSection")}>
                                            Descargar app
                                        </Button>
                                        <Box ml={2} />
                                        <Divider style={{height: 25}} orientation="vertical"/>
                                        <Box ml={2} />
                                    </Box>
                                    <Button href='/login' variant="outlined" color="primary">Iniciar sesión</Button>
                                </>
                            )}
                        </Box>
                    </Box>
                </Container>
            </Paper>
        </Box>
    ) 
}