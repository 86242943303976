import React, {useState}from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    MobileStepper,
    Typography,
    Box
} from '@material-ui/core'

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import grupoAmerica from '../Resources/Clientes/grupoAmerica.svg'
import meta from '../Resources/Clientes/meta.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);


const opinions = [
  {
    nombre: 'Nelson Martin',
    opinion: 'Hemos venido trabajando con Transtrip desde hace mas de 2 años. Tanto sus vehículos, como sus conductores, siempre están en excelentes condiciones. Durante la pandemia, nos han brindado un excelente servicio, trasladando a todo nuestro personal desde sus domicilios particulares hasta la compañía.',
    cargo: 'Responsable de Compras no Productivas',
    empresa: meta,
  },
  {
    nombre: 'Federico Galliano',
    opinion: 'Trasladamos por Transtrip a nuestro staff de figuras que trabajan en la radio. No hemos tenido inconvenientes, y siempre están predispuestos a solucionarme los problemas de movilidad. Durante los cumpleaños de nuestras figuras, siempre les dejan un regalo. Una empresa de traslados totalmente recomendable.',
    cargo: 'Productor General - Radio La Red',
    empresa: grupoAmerica
  },
  
];

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
}));

function SwipeableTextMobileStepper() {
  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = opinions.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box width={1}>
        <Typography 
            variant="h4"
            color="primary" 
            align="center"
            style={{fontWeight: "bold"}} 
            gutterBottom
        >
            Opiniones
        </Typography>
        <AutoPlaySwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            interval={6000}
            enableMouseEvents
        >
            {opinions.map((opinion, index) => (
                <div key={index}>
                    {Math.abs(activeStep - index) <= 2 ? (
                        <Box width={1} py={2}> 
                            <Typography variant="body2" align="center" gutterBottom>{opinion.opinion}</Typography>
                            <Box mt={2} display="flex" flexDirection="column" alignItems="center">
                                <Box 
                                    mb={1}
                                    mt={2}
                                    style={{
                                        backgroundImage: `url(${opinion.empresa})`,
                                        backgroundSize: "contain",
                                        backgroundPosition: "center center",
                                        backgroundRepeat: "no-repeat"
                                    }}
                                    width={240}
                                    height={45}
                                />
                                <Typography variant="subtitle1" align="center" ><b>{opinion.nombre}</b> - {opinion.cargo}</Typography>
                            </Box>
                        </Box>
                    ) : null}
                </div>
            ))}
        </AutoPlaySwipeableViews>
        <Box width={1} display="flex" justifyContent="center">
            <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
            />
        </Box>
    </Box>
  );
}

export default SwipeableTextMobileStepper;