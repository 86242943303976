import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"
import { createMuiTheme, ThemeProvider, responsiveFontSizes} from '@material-ui/core/styles'

import Index from './Pages/index'
import Login from './Pages/Login'
import Recovery from './Pages/Recovery'
import Beta from './Pages/beta'
import Error from './Pages/error'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#223567'
    },
    secondary: {
      main:  '#4261ab'
    }
  },
  typography: {
    fontFamily: 'MuseoSans'
  }
})
theme = responsiveFontSizes(theme)

export default () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/" exact>
            <Index />
          </Route>
          <Route path="/login" exact>
            <Login />
          </Route>
          <Route path="/recovery" exact>
            <Recovery />
          </Route>
          <Route path="/beta" exact>
            <Beta />
          </Route>
          <Route>
            <Error />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
