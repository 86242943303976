import React, {useRef, useState} from 'react'
import { useSpring, animated } from 'react-spring'
import ClientSlides from '../Components/ClientSlides'
import ClientOpinions from '../Components/ClientOpinions'

import {
    Typography,
    Box,
    Container,
    Button,
    Paper,
    Grid,
    TextField,
    IconButton,
    Tooltip,
    Snackbar,
    CircularProgress
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import {
    Send as SendIcon,
    ExpandMore as ExpandMoreIcon,
    Public as PublicIcon,
    Assignment as AssignmentIcon,
    Search as SearchIcon,
    Devices as DevicesIcon,
    Description as DescriptionIcon,
    Receipt as ReceiptIcon,
    Phone as PhoneIcon,
    Mail as MailIcon,
} from '@material-ui/icons'
import emailjs from 'emailjs-com'

import { makeStyles } from '@material-ui/core/styles'

//COMPONENTS
import Topbar from '../Components/Topbar'

// RESOURCES
import background from '../Resources/background.jpeg'
import {ReactComponent as Logo} from '../Resources/logo.svg'
import {Helmet} from "react-helmet"


//CLIENTES
import aa2000 from '../Resources/Clientes/aa2000.svg'
import agranaFruit from '../Resources/Clientes/agranaFruit.svg'
import amaszonas from '../Resources/Clientes/amaszonas.svg'
import bodegaFinMundo from '../Resources/Clientes/bodegaFinMundo.svg'
import cgc from '../Resources/Clientes/cgc.svg'
import corporacionAmerica from '../Resources/Clientes/corporacionAmerica.svg'
import dutyFree from '../Resources/Clientes/dutyFree.svg'
import flyest from '../Resources/Clientes/flyest.svg'
import grupoAmerica from '../Resources/Clientes/grupoAmerica.svg'
import jetsmart from '../Resources/Clientes/jetsmart.svg'
import laNacion from '../Resources/Clientes/la-nacion.svg'
import mercadoLibre from '../Resources/Clientes/mercado-libre.svg'
import meta from '../Resources/Clientes/meta.png'
import nestle from '../Resources/Clientes/nestle.svg'
import puig from '../Resources/Clientes/puig.svg'
import uar from '../Resources/Clientes/uar.png'
import wilobank from '../Resources/Clientes/wilobank.svg'
import flybondi from '../Resources/Clientes/flybondi.svg'


// IMAGES
import celu1 from '../Resources/celu1.png'
import celu1_2x from '../Resources/celu1_2x.png'
import celu2 from '../Resources/celu2.png'
import celu2_2x from '../Resources/celu2_2x.png'

//PLAY STORE
import playStore from '../Resources/playStore.svg'

const useStyles = makeStyles((theme) => ({
    bg: {
        background: `linear-gradient(5deg, rgba(255,255,255,.9) 46%, rgba(255,255,255,0.45) 100%), url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'bottom right',
    },
    whiteType: {
        color: "#fff"
    },
    paper: {
        width: '100%',
        paddingBottom: "56.25%",
        overflow: 'hidden'
    },
    reverseSection: {
        [theme.breakpoints.up('xs')]: {
            flexDirection: 'column-reverse',
          },
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
          },
    },
    lightGreyBg: {
        backgroundColor: theme.palette.grey[50],
        overflow: "hidden"
    },
    blueBg:{
        backgroundColor: theme.palette.primary.main,
    }
  }))

// MOUSE PARALLAX
const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2]
const speed5 = (x, y) => `translate3d(${x / 16}px,${y / 16}px,0)`
const speed1 = (x, y) => `translate3d(${x / 3}px,${y / 3}px,0)`

const AnimatedBox = animated(Box)

export default () => {
    // HOOKS
    const classes = useStyles()

    // STATES
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState('')
    const [severity, setSeverity] = useState('error')

    //REFS
    const destacados1 = useRef()
    const appSection = useRef()
    const opiniones = useRef()
    const contactSection = useRef()
    const inputNombre = useRef()
    const inputMail = useRef()
    const inputTel = useRef()

    // FUNCTIONS
    const [, setY] = useSpring(() => ({ y: 0 }))
    const scrollTo = data => {
        let value
        if(typeof data === "number"){
            value = data
        } else {
            if(typeof data === "string"){
                data = data === "destacados1" ? destacados1 : data === "appSection" ? appSection : data === "contactSection" ? contactSection : opiniones
            }
            value = window.scrollY + data.current.getBoundingClientRect().top - 54
        }
        setY({
            y: value,
            reset: true,
            from: { y: window.scrollY },
            onFrame: props => window.scroll(0, props.y)
        })
    }

    const [props, set] = useSpring(() => ({ 
        app: [0, 0],
        config: { 
            mass: 10, 
            tension: 550, 
            friction: 140 
        } 
    }))
    const style = (section, speed) => ({ transform: props[section].interpolate(speed) })

    const sendMail = e => {
        e.preventDefault()
        setLoading(true)

        const nombre = inputNombre.current.value
        const mail = inputMail.current.value
        const tel = inputTel.current.value

        emailjs.send(
            'transtrip', 
            'template_YmGADTkW', 
            {
                nombre: nombre,
                mail: mail,
                tel: tel,
            }, 
            'user_peBZe3SSl4yHHfDgGt3SF'
        )
        .then(() => {
            setSeverity("success")
            setMessage("Mail enviado correctamente")
            setOpen(true)
        }, (error) => {
            setSeverity("error")
            setMessage(error.text)
            setOpen(true)
        })
        .finally(() => setLoading(false))
        
    }
    const handleClose = () => setOpen(false)

    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://transtrip.com.ar/" />
                <meta name="description" content="Solicitá traslados desde la web y controlalos sin necesidad de vouchers y llamados" />
                <meta name="robots" content="index, follow"></meta>
            </Helmet>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
            <Topbar scrollTo={scrollTo}/>
            {/* HERO SECTION */}
            <Box 
                margin={0} 
                width={1} 
                minHeight="100vh" 
                display="flex" 
                flexDirection="column" 
                alignItems="stretch"
            > 
                <Box height={56} />
                <Box className={classes.bg} flexGrow={1} display="flex" alignItems="center">
                    <Container maxWidth="md">
                            <Box py={5}>
                                <Typography 
                                    variant="h3" 
                                    component="h1" 
                                    color="primary"
                                    align="center"
                                    gutterBottom
                                >Traslados empresariales. Sin papeles.  </Typography>
                                <Typography 
                                    variant="subtitle1" 
                                    align="center"
                                    gutterBottom
                                >Solicitá traslados desde la web y controlalos sin necesidad de vouchers y llamados.</Typography>
                                <Box mt={5} display="flex" justifyContent="center">
                                    <Tooltip title="Ver más">
                                        <IconButton onClick={() => scrollTo(destacados1)}>
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Box>
                    </Container>
                </Box>
                <Box width={1} py={4}>
                    <Container maxWidth="lg">
                        <Grid container spacing={4} justify="space-between">
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <ClientSlides delay={8} length={3}>
                                    <div className="image" style={{backgroundImage: `url(${mercadoLibre})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${meta})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${wilobank})`}}/>
                                </ClientSlides>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <ClientSlides delay={4} length={3}>
                                    <div className="image" style={{backgroundImage: `url(${aa2000})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${flybondi})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${jetsmart})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${flyest})`}}/>
                                </ClientSlides>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <ClientSlides delay={5} length={3}>
                                    <div className="image" style={{backgroundImage: `url(${laNacion})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${uar})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${amaszonas})`}}/>
                                </ClientSlides>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <ClientSlides delay={7} length={3}>
                                    <div className="image" style={{backgroundImage: `url(${nestle})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${bodegaFinMundo})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${cgc})`}}/>
                                </ClientSlides>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <ClientSlides delay={3} length={2}>
                                    <div className="image" style={{backgroundImage: `url(${grupoAmerica})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${corporacionAmerica})`}}/>
                                </ClientSlides>
                            </Grid>
                            <Grid item xs={6} sm={2} container justify="center" alignItems="center">
                                <ClientSlides delay={6} length={3}>
                                    <div className="image" style={{backgroundImage: `url(${dutyFree})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${puig})`}}/>
                                    <div className="image" style={{backgroundImage: `url(${agranaFruit})`}}/>
                                </ClientSlides>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
            {/* HERO SECTION */}
            {/* DESTACADOS SECTION */}
            <Box py={{xs: 10, sm: 15, md: 20}} ref={destacados1}>
                <Container maxWidth="md">
                    <Grid container spacing={4} justify="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <PublicIcon style={{fontSize: 50}} color="primary"/>
                                <Box mt={2}/>
                                <Typography variant="h6" style={{fontWeight: "bold"}} align="center" gutterBottom>Ubicación</Typography>
                                <Typography variant="body2" align="center">Conocé la ubicación del viaje en tiempo real, la ruta que realizó y toda la información histórica de cada viaje.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <AssignmentIcon style={{fontSize: 50}} color="primary"/>
                                <Box mt={2}/>
                                <Typography variant="h6" style={{fontWeight: "bold"}} align="center" gutterBottom>Seguridad</Typography>
                                <Typography variant="body2" align="center">Todos los pasajeros reciben un mail con la información del chofer y vehículo que los pasará a buscar para mayor seguridad en el traslado.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <ReceiptIcon style={{fontSize: 50}} color="primary"/>
                                <Box mt={2}/>
                                <Typography variant="h6" style={{fontWeight: "bold"}} align="center" gutterBottom>Sin papeles</Typography>
                                <Typography variant="body2" align="center">Firma del voucher digital. No hace falta controlar ningún papel, toda la información se encuentra disponible por sistema</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/* DESTACADOS SECTION */}
            {/* APP SECTION */}
            <Box 
                ref={appSection}
                py={{xs: 10, sm: 15, md: 20}} 
                className={classes.lightGreyBg}
                onMouseMove={({ clientX: x, clientY: y }) => set({ app: calc(x, y) })}
                onMouseLeave={() => set({app: [0,0]})}
            >
                <Container maxWidth="md">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={8}>
                            <Box
                                width={1}
                                textAlign={{
                                    xs: "center",
                                    sm: "left"
                                }}
                                boxSizing="border-box"
                                pr={{
                                    xs: 0,
                                    sm: 10
                                }}
                            >
                                <Typography 
                                    variant="h3"
                                    color="primary" 
                                    style={{fontWeight: "bold"}} 
                                    gutterBottom
                                >
                                    Descargá nuestra app
                                </Typography>
                                <Typography variant="subtitle1">Solicitá viajes desde donde estes. Enterate que chofer vehículo te van a buscar, controlá el recorrido, firmá digitalmente y más.</Typography>
                                <Box mt={4} display="inline-block">
                                    <Tooltip title="Descargar app">
                                        <a
                                            href="https://play.google.com/store/apps/details?id=com.arielvila.karcli.transtrip&hl=es_AR&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'"
                                            target="_blank"
                                        >
                                            <img 
                                                src={playStore}
                                                width="200px"
                                            />
                                        </a>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid 
                            item 
                            xs={12} 
                            sm={4}
                        > 
                            <Box
                                position="relative"
                                display="flex"
                                justifyContent={{
                                    xs: "center",
                                    sm: "flex-end"
                                }}
                            >
                                <AnimatedBox 
                                    width={280}
                                    position="absolute"
                                    top={{
                                        xs: "-5%",
                                        sm: "-10%"
                                    }}
                                    right={{
                                        xs: "15%",
                                        sm: "15%"
                                    }}
                                    zIndex={1}
                                    style={style("app", speed1)}
                                >
                                    <img 
                                        src={celu1}
                                        srcSet={`${celu1}, ${celu1_2x} 2x`}
                                        width="100%"
                                        alt="Celular con vista previa de la app"
                                    />
                                </AnimatedBox>
                                <AnimatedBox
                                    component="img"
                                    position="relative"
                                    right={{
                                        xs: "-10%",
                                        sm: 0
                                    }}
                                    src={celu2}
                                    srcSet={`${celu2}, ${celu2_2x} 2x`}
                                    style={style("app", speed5)}
                                    alt="Celular con vista previa de la app"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/* APP SECTION */}
            {/* DESTACADOS SECTION */}
            <Box py={{xs: 10, sm: 15, md: 20}}>
                <Container maxWidth="md">
                    <Grid container spacing={4} justify="center">
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <SearchIcon style={{fontSize: 50}} color="primary"/>
                                <Box mt={2}/>
                                <Typography variant="h6" style={{fontWeight: "bold"}} align="center" gutterBottom>Transparencia</Typography>
                                <Typography variant="body2" align="center">Podés ver cotizar el viaje y previsualizar la ruta en un mapa inclusive antes de solicitar el viaje.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <DevicesIcon style={{fontSize: 50}} color="primary"/>
                                <Box mt={2}/>
                                <Typography variant="h6" style={{fontWeight: "bold"}} align="center" gutterBottom>Responsive</Typography>
                                <Typography variant="body2" align="center">Podes solicitar los viajes desde cualquier dispositivo, ya sea descargando la app o desde la página web.</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box display="flex" alignItems="center" flexDirection="column">
                                <DescriptionIcon style={{fontSize: 50}} color="primary"/>
                                <Box mt={2}/>
                                <Typography variant="h6" style={{fontWeight: "bold"}} align="center" gutterBottom>Facturas</Typography>
                                <Typography variant="body2" align="center">Todas las facturas se emiten digitalmente y se descargan desde la plataforma.</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/* DESTACADOS SECTION */}
            {/* OPINIONES SECTION */}
            <Box ref={opiniones} py={5} className={classes.lightGreyBg}>
                <Container maxWidth="md">
                    <ClientOpinions />
                </Container>
            </Box>
            {/* OPINIONES SECTION */}
            {/* CONTACTO SECTION */}
            <Box ref={contactSection} className={classes.blueBg} pt={10} pb={6}>
                <Container maxWidth="md">
                    <Paper elevation={5}>
                        <Box pt={4}>
                            <Typography 
                                variant="h4"
                                align="center"
                                gutterBottom
                            >
                                Contacto 
                            </Typography>
                            <Typography variant="body2" align="center">
                                ¿Te interesa nuestro servicio? Completá el formulario, en breve nos comunicamos con vos
                            </Typography>
                        </Box>
                        <Box 
                            element="form"
                            width={1}
                            p={5}
                            onSubmit={sendMail}
                        >
                            <Grid container spacing={2} justify="center">
                                {loading ? (
                                    <Grid item xs={12}>
                                        <Box width={1} display="flex" justifyContent="center" p={2}>
                                            <CircularProgress />
                                        </Box>
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item xs={12} sm={5} md={4}>
                                            <TextField 
                                                name="nombre" 
                                                type="text" 
                                                label="Nombre" 
                                                variant="outlined" 
                                                inputRef={inputNombre}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={4}>
                                            <TextField 
                                                name="mail" 
                                                type="email" 
                                                label="Email" 
                                                variant="outlined" 
                                                inputRef={inputMail}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={4}>
                                            <TextField 
                                                name="telefono" 
                                                type="tel" 
                                                label="Teléfono" 
                                                inputRef={inputTel}
                                                variant="outlined" 
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box width={1} display="flex" justifyContent="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    endIcon={
                                                        <SendIcon />
                                                    }
                                                    onClick={sendMail}
                                                    type="submit"
                                                    disableElevation
                                                    size="large"
                                                >
                                                    Enviar
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Box>
                    </Paper>
                </Container>
            </Box>
            {/* CONTACTO SECTION */}
            {/* FOTER SECTION */}
            <Box pt={10} width={1}>
                <Container maxWidth="md">
                    <Box width={1} display="flex" flexDirection="column" alignItems="center">
                        <Logo width={200} />
                    </Box>
                    <Box my={6} width={1} display="flex" justifyContent="center" flexWrap="wrap">
                        <Box px={2}>
                            <Button
                                color="primary"
                                href="tel:+541147182020"
                                startIcon={
                                    <PhoneIcon />
                                }
                            >
                                (011) 4718-2020
                            </Button>
                        </Box>
                        <Box px={2}>
                            <Button
                                color="primary"
                                href="mailto:administracion@transtrip.com.ar"
                                startIcon={
                                    <MailIcon />
                                }
                            >
                                administracion@transtrip.com.ar
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <Box py={2} width={1} className={classes.lightGreyBg}> 
                    <Container maxWidth="md">
                        <Typography variant="caption" align="center" style={{display: "block"}}>
                            &copy; Copyright {new Date().getFullYear()}, Transtrip S.R.L. Todos los derechos reservados
                        </Typography>
                    </Container>
                </Box>
            </Box>
            {/* FOTER SECTION */}
        </>
    )
}





