import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";

import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

//HOOKS
import { useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

//RESOURCES
import bg from "../Resources/background.jpeg";
import { ReactComponent as Logo } from "../Resources/logo.svg";

export default function Login() {
  //HOOKS
  const theme = useTheme();
  const history = useHistory();

  // REFS
  const userInput = useRef();
  const passwordInput = useRef();
  const realForm = useRef();
  const realUserInput = useRef();
  const realPasswordInput = useRef();

  // STATES
  const [loading, setLoading] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [host, setHost] = useState(null);

  // EFFECTS
  useEffect(() => {
    document.title = "Transtrip | Iniciar sesión";
  }, []);

  // FUNCTIONS
  const getHost = async (triesLeft = 3) => {
    if (host) return host;

    try {
      const response = await fetch(
        "https://karbooking.nyc3.cdn.digitaloceanspaces.com/currhost.cnf"
      );
      const buffer = await response.arrayBuffer();
      const text = new TextDecoder("utf-8").decode(new Uint8Array(buffer));
      const newHost = text.trim();
      console.log("newHost", newHost);
      setHost(newHost);
      return newHost;
    } catch (error) {
      console.log("Error fetching host:", error);
      if (triesLeft > 0) {
        return getHost(triesLeft - 1);
      }
      throw new Error("Could not fetch host");
    }
  };

  const login = async (username, password, host) => {
    const formData = new FormData();
    formData.append("LoginForm[username]", username);
    formData.append("LoginForm[password]", password);
    formData.append("LoginForm[rememberMe]", 0);

    const response = await fetch(`${host}home/external-login`, {
      method: "POST",
      body: formData,
      cache: "no-store",
      xhrFields: {
        withCredentials: false,
      },
      headers: {},
    });

    const data = await response.json();

    if (data.success === false) {
      throw new Error(data.code);
    }

    realUserInput.current.value = username;
    realPasswordInput.current.value = password;
    realForm.current.submit();
  };

  const loginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const username = userInput.current.value;
    const password = passwordInput.current.value;

    try {
      const currentHost = await getHost();
      // Logout first
      await fetch(`${currentHost}home/logout-json`);
      await login(username, password, currentHost);
    } catch (err) {
      setErrorMessage(err.message);
      setOpenError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => setOpenError(false);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://transtrip.com.ar/login" />
        <meta
          name="description"
          content="Accedé a nuestra plataforma digital"
        />
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        style={{
          background: `url(${bg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Container>
          <Grid container spacing={2} justify="center">
            <Grid item xs={10} sm={8} md={5}>
              <Paper>
                <Box
                  px={3}
                  py={6}
                  my={3}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  maxHeight="80vh"
                  overflow="auto"
                  style={{ transition: "height 200ms ease-in-out" }}
                >
                  <Box component="a" href="/" width="45%">
                    <Logo width="100%" />
                  </Box>
                  <Box>
                    <Box
                      width={1}
                      height={2}
                      mt={1}
                      mb={2}
                      bgcolor={theme.palette.grey[200]}
                    />
                    <Typography variant="h6" color="primary" gutterBottom>
                      Iniciar Sesión
                    </Typography>
                  </Box>
                  {loading ? (
                    <Box my={4}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Box
                      component="form"
                      onSubmit={loginSubmit}
                      display="flex"
                      flexDirection="column"
                      width={1}
                      maxWidth={250}
                    >
                      <Box mt={4} />
                      <TextField inputRef={userInput} label="Usuario" />
                      <Box mt={2} />
                      <TextField
                        inputRef={passwordInput}
                        type="password"
                        label="Clave"
                      />
                      <Box mt={6} />
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disableElevation
                      >
                        Login
                      </Button>
                      <Box mt={4} />
                      <Button
                        onClick={() => history.push("/recovery")}
                        size="small"
                        disableElevation
                      >
                        Olvide mi contraseña
                      </Button>
                    </Box>
                  )}
                  <Box
                    display="none"
                    component="form"
                    ref={realForm}
                    action={`${host}home/login`}
                    method="POST"
                  >
                    <input
                      ref={realUserInput}
                      type="text"
                      name="LoginForm[username]"
                    />
                    <input
                      ref={realPasswordInput}
                      type="password"
                      name="LoginForm[password]"
                    />
                    <input
                      type="checkbox"
                      name="LoginForm[rememberMe]"
                      value="0"
                    />
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}
